.banner-st {
	&_line-bottom {
		&:after {
			content: "";
			position: absolute;
			top: 100%;
			left: 50%;
			width: 2px;
			height: 100px;
			background-color: rgba(#000, 0.3);
			margin-left: -1px;
			@include tabletMaxi {
				display: none;
			}
		}
		// @media (min-width: 1055px) {
		// 	.banner-st__inner {
		// 		padding-right: calc((100vw - 1600px)/2 + 150px);
		// 	}
		// }
	}
	&_push-top {
		margin-top: -132px;
		position: relative;
		z-index: 2;
		@include tabletMaxi {
			margin-top: -160px;
		}
		@include mobile {
			margin-top: 0;
		}
	}
	.container_right {
		@include mobile {
			padding-left: 0;
		}
	}
	&__inner {
		min-height: 560px;
		background: #9186FF;
		padding-right: 64px;
		padding-top: 100px;
		padding-bottom: 100px;
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: flex-start;
		color: #fff;
		padding-left: 198px;

		@media (min-width: 1600px) {
			padding-right: calc((100vw - 1600px) / 2 + 99px);
		}
		@include tabletMaxi {
			min-height: 800px;
			padding-left: 75px;
		}
		@include mobile {
			padding-left: 20px;
			padding-right: 20px;
			min-height: 560px;
		}
		.subtitle {
			margin-bottom: 25px;
			color: #FFD464;
		}
		.h3 {
			margin-bottom: 38px;
		}
		.subname {
			margin-top: 52px;
			@include tabletMaxi {
				margin-top: 32px;
			}
			@include mobile {
				margin-top: 28px;
				max-width: 90%;
				line-height: 130%;
			}
		}
	}
}

.container {
	&_right {
		max-width: none;
		padding: 0 135px;
		padding-right: 0;
		margin-right: 0;
		padding-left: 135px;
		margin-left: 0;
		width: auto;
		@media (min-width: 1600px) {
			margin-left: calc((100vw - 1600px) / 2);
			padding-left: 135px;
		}
		@include tabletMaxi {
			padding-left: 64px;
		}
		@include mobile {
			padding-left: 20px;
		}
	}
}

.subname {
	font-weight: 500;
	font-size: 18px;
	line-height: 100%;
	letter-spacing: 0.645em;
	text-transform: uppercase;
	color: #523AE2;
}

.banner-lg {
	position: relative;
	z-index: 1;
	overflow: hidden;
	&__inner {
		min-height: 680px;
		display: flex;
		justify-content: flex-end;
		flex-direction: column;
		padding: 190px 0;
		@include tabletMaxi {
			padding: 138px 0;
		}
		@include mobile {
			padding: 96px 0;
			min-height: 560px;
		}
	}
	&__bg {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		z-index: -1;
		overflow: hidden;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			display: block;
		}
	}
}


.js-parallax-container {
	position: relative;
	overflow: hidden;
}
.js-parallax {
	position: absolute;
	width: 100%;
	left: 0;
	height: 110%;
	top: -5%;

	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;

	transform: translate3d(0, 0, 0);
}

.txt-line {
	display: flex;
	align-items: center;
	font-weight: 500;
	font-size: 84px;
	line-height: 75px;
	letter-spacing: 0.1em;
	text-transform: uppercase;
	color: #523AE2;
	@include tabletMaxi {
		line-height: 1.2;
		font-size: 64px;
	}
	@include mobile {
		line-height: 1.2;
		font-size: 48px;
	}
	&_one {
		animation: {
			name: animline1;
			duration: 30s;
			timing-function: linear; // ease | ease-in-out
			delay: 0s; //задержка
			iteration-count: infinite; // infinite | <number>
			direction: alternate; // reverse; alternate; alternate-reverse;
			fill-mode: none; // none | forwards | backwards | both
			play-state: running; // running | paused
		}

	}
	&_two {
		justify-content: flex-end;
		animation: {
			name: animline2;
			duration: 30s;
			timing-function: linear; // ease | ease-in-out
			delay: 0s; //задержка
			iteration-count: infinite; // infinite | <number>
			direction: alternate; // reverse; alternate; alternate-reverse;
			fill-mode: none; // none | forwards | backwards | both
			play-state: running; // running | paused
		}
	}
	* {
		margin: 0 15px;
		transition: all 0.3s ease-in-out;
		&:hover {
			color: #FFA729;
		}
	}
	span {
		cursor: default;
	}
}

@keyframes animline1 {
	from {
		transform: translateX(0);
	}
	to {
		transform: translateX(-1000px);
	}
}

@keyframes animline2 {
	from {
		transform: translateX(0);
	}
	to {
		transform: translateX(1000px);
	}
}



.hero-txt__inner {
	padding-top: 200px;
	padding-bottom: 60px;
	max-width: 970px;
	@include tabletMaxi {
		padding-bottom: 60px;
	}
	@include mobile {
		padding-top: 255px;
		padding-bottom: 40px;
	}
	.h3 {
		color: #523AE2;
		margin-bottom: 30px;
		text-transform: uppercase;
		font-weight: 600;
	}
}

.doc-cards-section {
	margin-bottom: 90px;
}

.tabs__list_grid {
	display: flex;
	justify-content: space-between;
	align-items: center;
	@include tabletMaxi {
		display: block;
	}
}

.filter-tabs {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	@include tabletMaxi {
		justify-content: flex-start;
	}
	.filter-point {
		margin-left: 35px;
		@include tabletMaxi {
			margin-top: 22px;
			&:first-child {
				margin-left: 0;
			}
		}
	}
}

.filter-point {
	position: relative;
	z-index: 1;
	.check-box.active {
		cursor: default;
		.check-box__field:after {
			opacity: 1;
		}
		.check-box__txt {
			color: #ffd464;
		}
	}
	&__cap {
		cursor: pointer;
		&:after {
			transition: all 0.3s ease-in-out;
		}
	}
	&:hover {
		.filter-point__body {
			transform: rotateX(0);
		}
		.filter-point {
			&__cap {
				&:after {
					transform: rotate(180deg);
				}
			}
		}
	}
	.check-box {
		&:hover {
			.check-box__txt {
				color: #ffd464;
			}
		}
	}
}
.filter-point__body {
	position: absolute;
	margin-top: 20px;
	top: 100%;
	right: -15px;
	left: auto;
	width: 320px;
	transform: rotateX(90deg);
	transform-origin: center top;
	transition: all 0.3s ease-in-out;
	@include tabletMaxi {
		right: auto;
		left: -15px;
	}
}

.tabs__list_hidden-mob {
	@include mobile {
		display: none;
	}
}

.filter-mob {
	display: none;
	@include mobile {
		display: block;
	}
}


.filter-open {
	font-weight: 500;
	font-size: 14px;
	line-height: 100%;
	letter-spacing: 0.1em;
	text-decoration-line: underline;
	text-transform: uppercase;
	color: #523AE2;
	padding-right: 47px;
	display: inline-block;
	text-align: left;
	position: relative;
	margin-bottom: 34px;
	&:after {
		width: 30px;
		height: 30px;
		background: url(../img/plus.svg) center center no-repeat;
		content: "";
		position: absolute;
		right: 0;
		top: 50%;
		margin-top: -15px;
	}
}

.filter-mob__tt {
	font-weight: 500;
	font-size: 14px;
	line-height: 100%;
	letter-spacing: 0.1em;
	text-decoration-line: underline;
	text-transform: uppercase;
	color: #523AE2;
	position: relative;
	text-align: left;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	margin-bottom: 62px;
	padding-left: 10px;
	&-close {
		line-height: 0;
		width: 30px;
		height: 30px;
		display: flex;
		justify-content: center;
		align-items: center;
		img {
			flex-shrink: 0;
		}
	}
}

.filter-mob__list {
	margin-bottom: 64px;
	&-cap {
		padding: 20px 10px;
		font-size: 14px;
		line-height: 100%;
		letter-spacing: 0.04em;
		border-bottom: 2px solid rgba(#000, 0.3);
		margin-bottom: 50px;
		color: rgba(#000, 0.6);

	}
}

.list-mob-filter {
	&__item {
		padding: 0 10px;
		margin-bottom: 40px;
		&:last-child {
			margin-bottom: 0;
		}
	}
	&__link {
		position: relative;
		display: block;
		padding-left: 49px;
		font-weight: 400;
		font-size: 16px;
		line-height: 120%;
		text-align: center;
		letter-spacing: 0.03em;
		color: #523AE2;
		text-align: left;
		&.active {
			color: #FFA729;
			&:before {
				opacity: 1;
			}
		}
		&:before {
			content: "";
			position: absolute;
			top: 50%;
			left: 10px;
			width: 6px;
			height: 6px;
			margin-top: -3px;
			background: #FFA729;
			opacity: 0;
			border-radius: 50%;

		}
		&:after {
			content: "";
			position: absolute;
			top: 50%;
			margin-top: -13px;
			left: 0;
			width: 26px;
			height: 26px;
			opacity: 0.3;
			border: 2px solid #000000;
			border-radius: 8px;
		}
	}
}

.filter-open-body {
	touch-action: none;
	-webkit-overflow-scrolling: none;
	overflow: hidden;
	overscroll-behavior: none;
	.filter-mob__body {
		transform: translateX(0);
	}
}

.filter-mob__body {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	z-index: 10000;
	background-color: #f0f0f0;
	transform: translateX(-100%);
	display: none;
	@include mobile {
		display: block;
	}
	&-inner {
		padding: 33px 20px;
	}
}

.paggination {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 32px;
	@include mobile {
		justify-content: space-between;
	}
	&__value {
		font-size: 16px;
		line-height: 120%;
		text-align: center;
		margin: 0 52px;
		span {
			font-weight: 700;
			letter-spacing: 0.155em;
		}
	}
	&__arrow {
		width: 69px;
		height: 69px;
		border-radius: 50%;
		background: #F0F0F0;
		color: #9186FF;
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		&:hover {
			color: #fff;
			background: #523AE2;
		}
	}
}


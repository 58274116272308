// Input styling
#{$input-txt} {
	// styles
	background: rgba(#2C2C2C, 0.1);
	height: 70px;
	font-weight: 400;
	font-size: 16px;
	letter-spacing: 0.03em;
	color: rgba(#000, 0.9);
	padding: 0 35px;
	&::placeholder {
		color: rgba(#000, 0.4);
	}


	&[disabled] {
		// styles
		opacity: 1; // fix for safari
	}
}

textarea {
	overflow: auto;
	resize: none;
	height: 120px;
}

// Checkbox and Radio styling

.checkbox,
.radio {
	position: relative;

	input {
		position: absolute;
		width: 0;
		height: 0;
		opacity: 0;
	}

	label {
		cursor: pointer;

		&::before {
			content: "";
			// styles for Box
		}

		&::after {
			content: "";
			// styles for Check in unchecked state
		}
	}

	input:checked + label {

		&::after {
			// styles for Check in checked state
		}
	}
}

// Range input styling
@mixin rangeThumb {
	// thumb's styles
	cursor: pointer;
}

@mixin rangeTrack {
	// track's styles
}

.range-input {
	-webkit-appearance: none;

	&::-webkit-slider-thumb {
		-webkit-appearance: none;
		margin-top: -7px;

		@include rangeThumb;
	}

	&::-moz-range-thumb {
		@include rangeThumb;
	}

	&::-ms-thumb {
		@include rangeThumb;

		margin-top: 0;
	}

	&::-webkit-slider-runnable-track {
		@include rangeTrack;
	}

	&::-moz-range-track {
		@include rangeTrack;
	}

	&::-ms-track {
		width: 100%;
		color: transparent;
		cursor: pointer;
		background: transparent;
		border-color: transparent;

		@include rangeTrack;
	}
}

.filter-point {
	&__cap {
		padding-right: 30px;
		font-weight: 400;
		font-size: 16px;
		color: #523AE2;
		position: relative;
		display: inline-block;
		&:after {
			content: "";
			position: absolute;
			right: 0;
			top: 50%;
			margin-top: -5px;
			width: 16px;
			height: 9px;
			background: url(../img/ic-arrow-down.svg) center center no-repeat;

		}
	}
}

.filter-point__body {
	background: #9186FF;
	border-radius: 4px;
	padding: 37px 40px;
}

.check-box {
	display: inline-block;
	line-height: 26px;
	position: relative;
	cursor: pointer;
	&:checked {
		background-color: #000;
	}
	&__input {
		position: absolute;
		width: 1px;
		height: 1px;
		opacity: 0;
		&:checked {
			+ .check-box__field {
				&:after {
					opacity: 1;
				}
			}
			+ * + * {
				color: #FFD464;
			}
		}
	}
	&__txt {
		font-size: 16px;
		padding-left: 49px;
	}
	&__field {
		border: 2px solid rgba(#fff, 0.3);
		border-radius: 8px;
		position: absolute;
		top: 0;
		left: 0;
		width: 26px;
		height: 26px;
		&:after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			background: #FFD464;
			width: 6px;
			height: 6px;
			margin: auto;
			border-radius: 50%;
			opacity: 0;
		}

	}
}

.filter-point-list {
	> * {
		display: block;
		margin-bottom: 33px;
		&:last-child {
			margin-bottom: 0;
		}
	}
}



.article-box {
	background: #F0F0F0;
	position: relative;
	padding-top: 280px;
	padding-bottom: 420px;
	@include tabletMaxi {
		padding-bottom: 345px;
		padding-top: 220px;
	}
	@include mobile {
		padding-bottom: 182px;
		padding-top: 182px;
	}
	&:after {
		content: "";
		position: absolute;
		width: 2px;
		left: 50%;
		margin-left: -1px;
		bottom: 100%;
		background-color: #000;
		opacity: 0.3;
		height: 100px;
		@include tabletMaxi {
			height: 92px;
		}
		@include mobile {
			height: 56px;
		}
	}

	&__cap {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		margin-bottom: 105px;
		@include tabletMaxi {
			display: block;
		}
		@include mobile {
			margin-bottom: 89px;
		}
		.subtitle {
			color: #523AE2;
			margin-bottom: 32px;
			@include tabletMaxi {
				margin-bottom: 32px;
			}
		}
		&-left {
			margin-right: 214px;
			* {
				&:last-child {
					margin-bottom: 0;
				}
			}
			@include tabletMaxi {
				margin-bottom: 40px;
				margin-right: 0;
			}
		}
		&-right {
			flex-shrink: 0;
		}
	}
}

.article-cards {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 20px;
	@include tabletMaxi {
		grid-gap: 40px;
	}
	@include mobile {
		grid-gap: 30px;
		grid-template-columns: 1fr;
	}
}

.article-card {
	background-color: #fff;
	min-height: 360px;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	@include mobile {
		min-height: 400px;
	}
	&__value {
		font-weight: 300;
		font-size: 18px;
		text-transform: uppercase;
		color: #000000;
		opacity: 0.4;
		margin-bottom: 8px;
	}
	&__tt {
		font-weight: 500;
		font-size: 24px;
		line-height: 1.2;
		text-transform: uppercase;
		color: #000000;
		margin-bottom: 20px;
	}
	&:hover {
		.article-card__value {
			color: #523AE2;
			opacity: 0.4;
		}
		.article-card__tt {
			color: #523AE2;
		}
	}
	&__body {
		padding: 50px 54px;
		@include tabletMaxi {
			padding: 43px 36px;
		}
		@include mobile {
			padding: 46px 32px;
		}
	}
	&__footer {
		border-top: 1px solid #F0F0F0;
		padding: 26px 54px;
		@include tabletMaxi {
			padding: 26px 36px;
		}
		@include mobile {
			padding: 26px 32px;
		}
	}
	&__link {
		@include tabletMaxi {
			display: block;
		}
		@include mobile {
			display: inline-block;
		}
	}
}



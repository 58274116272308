.wrapper {
	overflow: hidden;
	// padding: 0 $wrap-lg;

	// @include tabletMaxi {
	// 	padding: 0 $wrap-md;
	// }

	// @include mobile {
	// 	padding: 0 $wrap-sm;
	// }

}

.container {
	width: 100%;
	max-width: 1600px;
	padding: 0 135px;
	margin: 0 auto;
	@include tabletMaxi {
		padding: 0 64px;
	}
	@include mobile {
		padding-left: 20px;
		padding-right: 20px;
	}
}

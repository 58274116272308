.mail-box {
	margin-top: 314px;
	margin-bottom: 154px;
	@include tabletMaxi {
		margin-top: 390px;
		margin-bottom: 140px;
	}
	@include mobile {
		margin-top: 390px;
		margin-bottom: 170px;
	}
	&__inner {
		position: relative;
	}
	&__tt {
		font-weight: 600;
		font-size: 48px;
		line-height: 110%;
		letter-spacing: 0.04em;
		text-transform: uppercase;
		margin-bottom: 22px;
		max-width: 770px;
		span {
			color: #523AE2;
		}
		@include mobile {
			font-size: 32px;
			margin-bottom: 20px;
		}
	}
	&__grid {
		max-width: 696px;
		display: grid;
		grid-template-columns: 1fr 200px;
		grid-gap: 20px;
		@include tabletMaxi {
			grid-template-columns: auto;
			max-width: 476px;
			grid-gap: 15px;
			.btn {
				max-width: 200px;
			}
		}
		@include mobile {
			grid-gap: 10px;
		}
	}
	&__field {
		height: 48px;
		padding-left: 33px;
		padding-right: 33px;
		font-size: 16px;
		background-color: #F0F0F0;
	}
}

input {
	&.mail-box__field {
		height: 48px;
		padding-left: 33px;
		padding-right: 33px;
		font-size: 16px;
		background-color: #F0F0F0;
	}
}

.mail-box__disk-one {
	position: absolute;
	right: -180px;
	top: -160px;
	width: 360px;
	height: 360px;
	border-radius: 50%;
	border: 2px solid rgba(#FFD464, 0.6);
	animation: {
		name: circlForm1;
		duration: 60s;
		timing-function: linear;
		iteration-count: infinite;
	}
	@include tabletMaxi {
		top: -245px;
		right: -153px;
	}
	@include mobile {
		top: -325px;
    right: -120px;
	}

	&:after {
		content: "";
		position: absolute;
		width: 28px;
		height: 28px;
		border-radius: 50%;
		background: #FFD464;
		top: -15px;
		left: 50%;
		margin-left: -14px;
	}
	span {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		animation: {
			name: circlForm2;
			duration: 80s;
			timing-function: linear;
			iteration-count: infinite;
		}
		&:after {
			content: "";
			position: absolute;
			width: 10px;
			height: 10px;
			border-radius: 50%;
			background: #FFD464;
			top: -6px;
			left: 50%;
			margin-left: -5px;
		}
	}
}

.mail-box__disk-two {
	position: absolute;
	right: -58px;
	top: -138px;
	width: 214px;
	height: 214px;
	border-radius: 50%;
	border: 2px solid #523AE2;
	animation: {
		name: circlForm3;
		duration: 60s;
		timing-function: linear;
		iteration-count: infinite;
	}
	@include tabletMaxi {
		top: -217px;
    right: -136px;
	}
	@include mobile {
		top: -302px;
    right: 3px;
	}
	&:after {
		content: "";
		position: absolute;
		width: 16px;
		height: 16px;
		border-radius: 50%;
		background: #523AE2;
		top: -9px;
		left: 50%;
		margin-left: -8px;
	}
}

@keyframes circlForm1 {
	from {
		transform: rotate(360deg);
	}
	to {
		transform: rotate(0deg);
	}
}

@keyframes circlForm2 {
	from {
		transform: rotate(-60deg);
	}
	to {
		transform: rotate(300deg);
	}
}

@keyframes circlForm3 {
	from {
		transform: rotate(-180deg);
	}
	to {
		transform: rotate(180deg);
	}
}


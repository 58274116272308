.custom-select {
	&__name {}

	&__list {}

	&__item {
		&--disabled {
			display: none;
		}
	}
}

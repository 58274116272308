.slider {
	overflow: hidden;
	padding: 185px 0;
	&-wrapper {
		position: relative;
	}
	@include tabletMaxi {
		padding: 208px 0 200px;
	}
	@include mobile {
		padding: 195px 0 170px;
	}
	.swiper {
		overflow: visible;
		// padding-left: 90px;
		max-width: 335px;
		margin-left: 0;
		margin-left: 250px;
		margin-right: 0;
		padding-bottom: 65px;
		position: static;
		@include tabletMaxi {
			margin-left: calc(50% - 169px);
			padding-bottom: 80px;
		}
		@include mobile {
			padding-bottom: 85px;
		}
		&-wrapper {
			position: relative;
		}
		.swiper-slide {
			width: 335px !important;
			height: 335px;

			// width: 650px;
			// height: 650px;
			&:nth-child(2),
			&:nth-child(6),
			&:nth-child(10) {
				.slider-circle__circl {
					animation-name: circleAnim1;
				}
			}
			&:nth-child(3),
			&:nth-child(7),
			&:nth-child(11) {
				.slider-circle__circl {
					animation-name: circleAnim2;
				}
			}
			&:nth-child(4),
			&:nth-child(8),
			&:nth-child(12) {
				.slider-circle__circl {
					animation-name: circleAnim3;
				}
			}
			// &:nth-child(6),
			// &:nth-child(11) {
			// 	.slider-circle__circl {
			// 		animation-name: circleAnim1;
			// 	}
			// }
		}
		// .swiper-slide.swiper-slide-duplicate.swiper-slide-prev {
		// 	&[data-swiper-slide-index="3"] {
		// 		margin-right: 158px;
		// 	}
		// }
		.swiper-slide.swiper-slide-duplicate.swiper-slide-duplicate-active {
			&[data-swiper-slide-index="0"] {
				position: relative;
				left: -158px;
				@include tabletMaxi {
					left: -130px;
				}
				+ div {
					position: relative;
					left: -158px;
					@include tabletMaxi {
						left: -130px;
					}
					+ div {
						position: relative;
						left: -158px;
						@include tabletMaxi {
							left: -130px;
						}
						+ div {
							position: relative;
							left: -158px;
							@include tabletMaxi {
								left: -130px;
							}
							+ div {
								margin-left: -158px;
								@include tabletMaxi {
									margin-left: -130px;
								}
							}
						}
					}
				}
			}
		}
		.swiper-slide +  {
			.swiper-slide.swiper-slide-duplicate.swiper-slide-duplicate-active {
				&[data-swiper-slide-index="0"],
				&[data-swiper-slide-index="0"] + div,
				&[data-swiper-slide-index="0"] + div + div,
				&[data-swiper-slide-index="0"] + div + div + div,
				&[data-swiper-slide-index="0"] + div + div + div + div {
					margin-left: 0 !important;
					left: 0 !important;
					@include tabletMaxi {
						margin-left: 0 !important;
						left: 0 !important;
					}
				}
			}
		}
		.swiper-slide-active {
			width: 650px !important;
			height: 650px;
			margin: 28px 0;
			@include tabletMaxi {
				width: 601px !important;
				height: 601px;
			}

			// &:not(.swiper-slide-duplicate) {
			// 	&[data-swiper-slide-index="0"] {
			// 		margin-left: -158px;
			// 		@include tabletMaxi {
			// 			margin-left: -130px;
			// 		}
			// 	}
			// }

			// &:first-child {
			// 	margin-left: -158px;
			// 	@include tabletMaxi {
			// 		margin-left: -130px;
			// 	}
			// }
		}
	}
	.swiper-wrapper {
		align-items: center;
	}
}

.swiper-button-next {
	position: absolute;
	top: 83px;
	left: 118px;
	z-index: 2;
	width: 69px;
	height: 69px;
	border-radius: 50%;
	background: #FFA729;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	cursor: pointer;
	&.swiper-button-disabled {
		display: none;
	}
	@include tabletMaxi {
		left: 50%;
		margin-left: -265px;
		width: 62px;
		height: 62px;
	}
	@include mobile {
		left: 21px;
		top: -41px;
		margin-left: 0;
	}
	&:hover {
		background: #ffd464;
	}
}

.swiper-pagination {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

.swiper-pagination-bullet {
	width: 8px;
	height: 8px;
	border-radius: 50%;
	margin: 0 13px;
	background: #523AE2;
	cursor: pointer;

	&-active {
		background: #FFA729;
		cursor: default;

	}
}

.slider-circle {
	width: 100%;
	height: 100%;
	padding: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	position: relative;
	z-index: 1;

	&__value {
		font-weight: 500;
		font-size: 14px;
		line-height: 100%;
		text-align: center;
		letter-spacing: 0.1em;
		color: #523AE2;
		opacity: 0.6;
		margin-bottom: 15px;
	}
	&__name {
		font-weight: 500;
		font-size: 24px;
		line-height: 120%;
		text-align: center;
		letter-spacing: 0.03em;
		color: #523AE2;
		margin-bottom: 29px;
	}
	&__txt {
		font-size: 18px;
		line-height: 130%;
		text-align: center;
		letter-spacing: 0.04em;
		max-width: 400px;
		margin-left: auto;
		margin-right: auto;
		max-height: 0;
		overflow: hidden;
		text-overflow: ellipsis;
		transition: all 0.7s ease-in-out;
		opacity: 0;
		@include mobile {
			max-width: calc(100vw - 40px);
		}
	}
	&__link {
		font-weight: 500;
		font-size: 14px;
		line-height: 100%;
		text-align: center;
		letter-spacing: 0.1em;
		text-decoration-line: underline;
		color: #FFA729;
		max-height: 0;
		overflow: hidden;
		text-overflow: ellipsis;
		opacity: 0;
		transition: all 0.7s ease-in-out;

		&:hover {
			text-decoration: none;
		}
	}
	&__circl {
		position: absolute;
		width: 386px;
		height: 386px;
		left: 50%;
		top: 50%;
		margin-left: -193px;
		margin-top: -193px;
		// transform: translate(-50%, -50%);
		border-radius: 50%;
		border: 1px solid #523AE2;
		transition: all 0.2s ease-in-out;
		z-index: -1;

		animation: {
			name: circleAnim;
			duration: 60s;
			timing-function: linear; // ease | ease-in-out
			delay: 0s; //задержка
			iteration-count: infinite; // infinite | <number>
			direction: normal; // reverse; alternate; alternate-reverse;
			fill-mode: none; // none | forwards | backwards | both
			play-state: running; // running | paused
		}

		&:after {
			content: "";
			position: absolute;
			width: 22px;
			height: 22px;
			top: -11px;
			left: 50%;
			margin-left: -11px;
			border-radius: 50%;
			background: #523AE2;
		}
	}
}

@keyframes circleAnim {
	from {
		transform: rotate(360deg);
	}
	to {
		transform: rotate(0deg);
	}
}

@keyframes circleAnim1 {
	from {
		transform: rotate(180deg);
	}
	to {
		transform: rotate(-180deg);
	}
}

@keyframes circleAnim2 {
	from {
		transform: rotate(90deg);
	}
	to {
		transform: rotate(-270deg);
	}
}

@keyframes circleAnim3 {
	from {
		transform: rotate(270deg);
	}
	to {
		transform: rotate(-90deg);
	}
}


.swiper-slide-active {
	.slider-circle__name {
		margin-bottom: 59px;
		font-size: 38px;
		color: #FFA729;
	}
	.slider-circle__value {
		color: #FFA729;
		margin-bottom: 11px;
		margin-top: 20px;
		@include mobile {
			margin-top: 30px;
		}
	}
	.slider-circle__txt {
		margin-bottom: 45px;
		max-height: 400px;
		opacity: 1;
		@include tabletMaxi {
			margin-bottom: 24px;
		}
	}
	.slider-circle__link {
		max-height: 100px;
		opacity: 1;
	}
	.slider-circle__circl {
		border-color: #FFA729;
		width: 707px;
		height: 707px;
		margin-left: -353px;
		margin-top: -353px;
		@include tabletMaxi {
			width: 638px;
			height: 638px;
			margin-left: -319px;
			margin-top: -319px;
		}
		@include mobile {
			width: 707px;
			height: 707px;
			margin-left: -353px;
			margin-top: -353px;
		}
		&:after {
			background-color: #FFA729;
		}
	}
}


.card-personal {
	display: inline-block;
	overflow: hidden;
	position: relative;
	&__img {
		height: 320px;
		position: relative;
		overflow: hidden;
		border-bottom: 2px solid #523AE2;
		margin-bottom: 36px;
		@include mobile {
			height: 170px;
			margin-bottom: 16px;
		}
		img {
			width: 100%;
			height: 100%;
			display: inline-block;
			object-fit: cover;
		}
	}
	&__name {
		font-weight: 500;
		font-size: 24px;
		margin-bottom: 13px;
		@include mobile {
			font-size: 20px;
			line-height: 1.2;
		}
		&-desc {
			font-size: 14px;
			letter-spacing: 0.1em;
			color: #523AE2;
		}
	}
	&:hover {
		.card-personal__img {
			border-color: #FFA729;
		}
		.card-personal__name {
			color: #FFA729;
		}
		.card-personal__name-desc {
			color: #FFA729;
		}
	}
}

.caption__inner {
	margin-bottom: 87px;
	&_half-push {
		margin-left: calc(50% + 10px);
		@include tabletMaxi {
			margin-left: 0;
		}
	}
	@include mobile {
		margin-bottom: 50px;
	}
}

@include mobile {
	.caption .caption__inner .h3 {
		margin-bottom: 30px;
	}
}

.cards-personal {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 20px;
	@include tabletMaxi {
		grid-template-columns: repeat(2, 1fr);
		grid-column-gap: 40px;
		grid-row-gap: 55px;
	}
	@include mobile {
		grid-template-columns: repeat(2, 1fr);
		grid-column-gap: 26px;
		grid-row-gap: 33px;
	}
	&_gap-big {
		grid-row-gap: 70px;
		@include tabletMaxi {
			grid-row-gap: 56px;
		}
		@include mobile {
			grid-row-gap: 37px;
		}
	}
}

.cards-section {
	margin: 204px 0 244px;
	@include tabletMaxi {
		margin-bottom: 200px;
	}
	@include mobile {
		margin-top: 170px;
		margin-bottom: 170px;
	}
	&_pillar {
		margin-top: 185px;
		margin-bottom: 195px;
		.caption__inner {
			margin-bottom: 70px;
		}
		@include tabletMaxi {
			margin-bottom: 180px;
			.caption__inner {
				margin-bottom: 60px;
				.h3 {
					margin-bottom: 30px;
				}
			}
		}
		@include mobile {
			margin-top: 175px;
			margin-bottom: 135px;
		}
	}
	&_governance {
		margin-bottom: 134px;
		margin-top: 0;
		@include tabletMaxi {
			margin-bottom: 100px;
		}

		@include mobile {
			margin-bottom: 123px;
		}
	}
}



html,
body {
	font-weight: 400;
}

html {
	height: 100%;
}


body {
	font-family: $font-1;
	font-weight: 400;
	color: $c-black;
	font-size: 18px;
	line-height: 120%;
	letter-spacing: 0.03em;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr auto;
	min-height: 100%;
}

main {
	width: 100%;
}

img,
video,
svg {
	display: block;
	max-width: 100%;
	height: auto;
}

button,
a,
[class *= "button"],
[class *= "btn"],
[class *= "link"],
[type *= "submit"] {
	cursor: pointer;
}

.btn {
	position: relative;
	display: inline-block;
	outline: none;
	box-shadow: none;
	text-decoration: none;
	margin: 0;
	border: none;
	text-align: center;
	font-weight: 500;
	font-size: 14px;
	line-height: 24px;
	padding: 12px 30px;
	min-width: 200px;
	letter-spacing: 0.05em;
	text-transform: uppercase;
	background: #FFA729;
	border-radius: 4px;
	color: rgba(#000, 0.75);
	transition: all 0.15s ease-in-out;
	cursor: pointer;
	&:hover {
		text-decoration: none;
		outline: none;
		background: #FFD464;
	}
	>* {
		position: relative;
		z-index: 2;
	}

	transition-property: color,background-color,border-color,fill,stroke,opacity,box-shadow,transform,filter,-webkit-text-decoration-color,-webkit-backdrop-filter;
	transition-property: color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter;
	transition-property: color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter,-webkit-text-decoration-color,-webkit-backdrop-filter;
	transition-timing-function: cubic-bezier(.215,.61,.355,1);
	transition-duration: .3s;
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
	// border-radius: 9999px;
	// box-shadow: inset 0 0 0 1px transparent;
	will-change: transform;
	overflow: hidden;
	// text-transform: uppercase;

	&:after {
		transition-property: color,background-color,border-color,fill,stroke,opacity,box-shadow,transform,filter,-webkit-text-decoration-color,-webkit-backdrop-filter;
    transition-property: color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter;
    transition-property: color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter,-webkit-text-decoration-color,-webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(.215,.61,.355,1);
    transition-duration: .7s;
    content: "";
    position: absolute;
    border-radius: 50%;
    left: -3rem;
    right: -3rem;
    top: -100%;
    bottom: -100%;
    transform-origin: top;
    transform: scaleY(0);
    will-change: transform;
	background: #FFD464;
	}

	&:hover {
		&:after {
			transform-origin: bottom;
			transform: scaleY(1);
		}
	}
}

.point-btn, .tab-list__item {
	position: relative;
	display: inline-block;
	outline: none;
	box-shadow: none;
	text-decoration: none;
	margin: 0;
	font-weight: 400;
	font-size: 16px;
	line-height: 100%;
	padding-bottom: 20px;
	letter-spacing: 0.03em;
	color: #523AE2;
	cursor: pointer;
	&:after {
		content: "";
		position: absolute;
		bottom: 0;
		left: 50%;
		width: 6px;
		height: 6px;
		margin-left: -3px;
		background: currentColor;
		opacity: 0;
		border-radius: 50%;

	}
	&:hover, &.active {
		color: #FFA729;
	}
	&.active {
		&:after {
			opacity: 1;
		}
	}
}

.link-st {
	font-weight: 400;
	font-size: 18px;
	line-height: 100%;
	display: inline-block;
	text-decoration: none;
	cursor: pointer;
	&:hover {
		text-decoration: underline;
	}
}

.link-arrow {
	transition: 0.3s cubic-bezier(0.8,0,0.4,1);
	transform: translateX(0);
	padding-right: 36px;
	padding-left: 0;
	text-decoration: none;
	position: relative;

	font-weight: 500;
	font-size: 14px;
	line-height: 100%;
	letter-spacing: 0.04em;
	text-transform: uppercase;
	color: #523AE2;
	display: inline-block;
	&:before, &:after {
		transition: 0.3s cubic-bezier(0.8,0,0.4,1);

		position: absolute;
    top: 0;
    bottom: 0;
    width: 36px;
    background: url(../img/ic-arrow-righ-sm.svg) no-repeat;
    background-size: 17px 14px;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    content: "";
	}

	&:before {
		left: 0;
    background-position: left center;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: left;
    transform-origin: left;
    -webkit-mask-position: left center;
    mask-position: left center;
	}

	&:after {
		right: 0;
    background-position: right center;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: right;
    transform-origin: right;
    -webkit-mask-position: right center;
    mask-position: right center;
	}

	&:hover {
		padding-right: 0;
		padding-left: 36px;
		&:before {
			transform: scaleX(1);
		}
		&:after {
			transform: scaleX(0);
		}
	}
}

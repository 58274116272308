.s-push-top {
	margin-top: -90px;
	margin-bottom: 185px;
	@include mobile {
		margin-top: 0;
		margin-bottom: 170px;
	}
}

.img-big {
	height: 600px;
	overflow: hidden;
	margin-bottom: 190px;
	img {
		width: 100%;
		height: 100%;
		display: block;
		object-fit: cover;
	}
	@include tabletMaxi {
		height: 800px;
		margin-bottom: 200px;
	}
	@include mobile {
		height: 560px;
		margin-bottom: 170px;
		margin-left: -20px;
		margin-right: -20px;
	}
}

.caption_push-left {
	margin-left: 198px;
	margin-bottom: 108px;
	@include tabletMaxi {
		margin-left: 0;
		margin-bottom: 93px;
	}
	@include mobile {
		margin-bottom: 80px;
	}
}

.caption_sm {
	.h3 {
		margin-bottom: 40px;
		letter-spacing: 0.04em;
		@include tabletMaxi {
			margin-bottom: 30px;
		}
		@include mobile {
			margin-bottom: 50px;
		}
	}
	p {
		max-width: 675px;
	}
}

.cards-value {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 20px;
	@include tabletMaxi {
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 40px;
	}
	@include mobile {
		grid-template-columns: 1fr;
		grid-gap: 30px;
	}
	.link-arrow {
		display: none;
		@include tabletMaxi {
			display: block;
			width: 100%;
			margin-top: 14px;
		}
	}
}


.card-value {
	position: relative;
	background: #F0F0F0;
	&:hover {
		.card-value__open {
			opacity: 0;
			transition: all 0.3s ease-in-out;
		}
		.card-value__open {
			opacity: 1;
			z-index: 1;
			>* {
				opacity: 1;
				transition: all 0.3s 0.5s ease-in-out;
			}
		}
	}
	&__inner {
		padding: 49px 45px;
		opacity: 1;
		transition: all 0.3s 0.5s ease-in-out;
		min-height: 410px;
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		align-items: flex-start;
		@include tabletMaxi {
			padding: 48px 33px 53px;
		}
		@include mobile {
			padding: 48px 43px 53px;
		}
	}
	&__numb {
		font-size: 16px;
		line-height: 120%;
		letter-spacing: 0.03em;
		display: flex;
		align-items: flex-start;
		color: rgba(#000, 0.6);
		padding-top: 3px;
		span {
			color: #000;
			font-weight: 500;
			font-size: 64px;
			line-height: 100%;
			letter-spacing: 0em;
			margin-right: 13px;
			margin-top: -5px;
		}
	}
	&__desc {
		font-weight: 600;
		font-size: 24px;
		line-height: 120%;
		letter-spacing: 0.03em;
		color: #523AE2;
		@include tabletMaxi {
			margin-top: auto;
		}
	}
	&__open {
		position: absolute;
		opacity: 0;
		transition: all 0.5s ease-in-out;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		flex-direction: column;
		padding: 49px 45px;
		background: #9186FF;
		z-index: -1;
		>* {
			opacity: 0;
			transition: all 0.3s ease-in-out;
		}
		@include tabletMaxi {
			padding-left: 35px;
			padding-right: 35px;
		}
		@include mobile {
			padding-left: 45px;
			padding-right: 45px;
		}
	}

	&__tt {
		font-weight: 600;
		font-size: 24px;
		line-height: 120%;
		letter-spacing: 0.03em;
		color: #fff;
		margin-bottom: 38px;
	}
	&__cap {
		p {
			font-size: 16px;
			line-height: 140%;
			letter-spacing: 0.03em;
			color: rgba(#000, 0.6);
		}
	}
	&__links {
		font-weight: 500;
		font-size: 14px;
		line-height: 100%;
		letter-spacing: 0.1em;
		text-transform: uppercase;
		margin-top: 30px;
		li {
			margin-bottom: 16px;
			&:last-child {
				margin-bottom: 0;
			}
		}
		a {
			text-decoration-line: underline;
			&:hover {
				text-decoration: none;
			}
		}
	}
}

.caption__inner_left-push {
	margin-left: 198px;
	@include tabletMaxi {
		margin-left: 0;
	}
}




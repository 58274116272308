.header {
	z-index: map-get($z-index, header);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
	&_light {
		color: #fff;
		.header__menu .menu {
			color: #fff;
		}
		.header__logo {
			color: #fff;
		}
	}
	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 100%;
		background-color: #9186FF;
		transform: translateX(-100%);
		transition: all 0.3s ease-in-out;
		z-index: -1;
	}
	&__inner {
		padding: 26px 0;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	&__logo {
		max-width: 180px;
		margin-right: 20px;
		color: #523AE2;
		position: relative;
		top: -5px;
	}
	&__menu {
		.menu {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			font-size: 16px;
			line-height: 100%;
			color: #000;
			&__item {
				position: relative;
				&_dd {
					z-index: 1;
					&:after {
						content: "";
						position: absolute;
						top: 100%;
						left: -40px;
						right: -40px;
					}
					.menu-dd-list {
						transform: rotateX(90deg);
						transform-origin: top center;

					}
					.menu__link {
						transition: all 0.3s ease-in-out;
					}
					&:hover {
						.menu-dd-list {
							transform: rotateX(0);
							transition: all 0.3s ease-in-out !important;
						}
						&:after {
							height: 22px;
						}
						.menu__link {
							color: #FFA729;
							&:before {
								transform: rotate(225deg);
								margin-top: -3px;
								border-color: #FFA729;
							}
						}
					}
				}
				&.btn {
					margin-left: 53px;
				}
				margin-left: 45px;
				&.active .menu__link {
					cursor: default;
					&:not(.btn) {
						color: #FFA729;
						&:after {
							opacity: 1;
						}
					}
				}
			}
			&__link {
				position: relative;
				display: inline-block;
				&_dd {
					padding-right: 26px;
					position: relative;
					&:before {
						content: "";
						width: 8px;
						height: 8px;
						transform: rotate(45deg);
						border: 1px solid currentColor;
						border-left: none;
						border-top: none;
						position: absolute;
						right: 0;
						top: 50%;
						margin-top: -6px;
						transition: all 0.3s ease-in-out;

					}
				}
				&:not(.btn) {
					&:hover {
						color: #FFA729;
					}
					&:after {
						content: "";
						position: absolute;
						top: 100%;
						left: 50%;
						margin-top: 14px;
						margin-left: -3px;
						width: 6px;
						height: 6px;
						border-radius: 50%;
						background: currentColor;
						opacity: 0;
					}
				}
			}
		}
		@include tabletMaxi {
			.nav-menu {
				display: none;
			}
		}
	}
}

.burger-menu {
	padding: 15px 3px;
	margin-right: -3px;
	cursor: pointer;
	position: relative;
	display: none;
	@include tabletMaxi {
		display: block;
	}
	span {
		height: 1px;
		background-color: #523AE2;
		width: 24px;
		position: relative;
		display: block;
		transition: all 0.3s ease-in-out;
		&:before, &:after {
			transition: all 0.3s ease-in-out;
			content: "";
			position: absolute;
			height: 1px;
			background-color: #523AE2;
			transform: rotate(0);
			transform-origin: center center;
			width: 100%;
			top: 0;
			left: 0;
			right: 0;
		}
		&:after {
			margin-top: -6px;
		}
		&:before {
			margin-top: 6px;
		}
	}
}

.menu-dd-list {
	position: absolute;
	top: 100%;
	margin-top: 22px;
	left: -40px;
	display: flex;
	flex-direction: column;
	background: #9186FF;
	border-radius: 4px;
	padding: 35px 40px;
	.check-box {
		margin-bottom: 33px;
		white-space: nowrap;
		color: #000;

		cursor: pointer;
		&__txt {
			padding-left: 0;
		}
		&:last-child {
			margin-bottom: 0;
		}
		&:hover {
			.check-box__txt {
				color: #FFD464;
			}
		}
		&.active {
			cursor: default;
			.check-box__txt {
				color: #FFD464;
			}
			.check-box__field {
				&:after {
					opacity: 1;
				}
			}
		}
	}
}

.menu-open {
	touch-action: none;
	-webkit-overflow-scrolling: none;
	overflow: hidden;
	overscroll-behavior: none;
	.menu-header-mob {
		transform: translateX(0);
	}
	.header {
		&:before {
			transform: translateX(0);
		}
		&__logo {
			color: #fff;
		}
	}
	.burger-menu {
		span {
			background-color: transparent;
			&:after {
				transform: rotate(45deg);
				margin-top: 0;
				background-color: #fff;
			}
			&:before {
				transform: rotate(-45deg);
				margin-top: 0;
				background-color: #fff;
			}
		}
	}
}

.menu-header-mob {
	transform: translateX(-100%);
	transition: all 0.3s ease-in-out;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	background: #9186FF;
	z-index: 10;
	padding-top: 92px;
	display: none;
	@include tabletMaxi {
		display: block;
	}
	&__wrapp {
		min-height: 100%;
		padding: 20px 64px 50px;
		display: flex;
		justify-content: flex-end;
		align-items: flex-start;
		flex-direction: column;
		@include mobile {
			padding-left: 20px;
			padding-right: 20px;
			padding-bottom: 20px;
		}
	}
}

.mob-menu {
	font-size: 24px;
	line-height: 1;
	color: #fff;
	@include mobile {
		font-size: 18px;
	}
	&__link {
		display: block;
		padding: 27px 0;
		@include mobile {
			padding: 20px 0;
		}
		&:hover {
			color: #FFA729;
		}
	}
}

.header {
	transition: all 0.5s ease-in-out;

	&--fixed {
		position: fixed;
		background: #523AE2;
		color: #fff;
		.header__menu .menu {
			color: #fff;
		}
		.header__logo {
			color: #fff;
		}
		.burger-menu span:after, .burger-menu span:before, .burger-menu span {
			background-color: #fff;
		}
	}
	&--hide {
		top: -100%;
		position: fixed;
		background: #523AE2;
		.header__logo {
			color: #fff;
		}
	}
}

.menu-open {
	.header--fixed .burger-menu span {
		background-color: transparent;
	}
}


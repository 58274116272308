.caption-circle {
	margin: 244px 0 245px;
	@include tabletMaxi {
		margin-top: 200px;
		margin-bottom: 224px;
	}
	@include mobile {
		margin-top: 170px;
		margin-bottom: 610px;
	}
	&__inner {
		position: relative;
		z-index: 1;
	}
	.h2 {
		margin-bottom: 42px;
		text-transform: uppercase;
		max-width: 1030px;
		font-weight: 600;
		font-size: 48px;
		line-height: 110%;
		letter-spacing: 0.04em;
		@include tabletMaxi {
			font-size: 38px;
		}
		@include mobile {
			font-size: 36px;
		}
		span {
			color: #523AE2;
		}
	}
	&__disk {
		border-radius: 50%;
		position: absolute;
		z-index: -1;
		top: 50%;
		transform: translateY(-50%);
		border: 2px solid currentColor;
		&:after {
			content: "";
			position: absolute;
			border-radius: 50%;
			left: 50%;
			background-color: currentColor;
		}
		&_one {
			width: 505px;
			height: 505px;
			right: -289px;
			margin-top: 17px;
			color: #FFA729;
			border-color: rgba(#FFA729, 0.6);
			animation: {
				name: circleCap1;
				duration: 30s;
				timing-function: linear;
				iteration-count: infinite;
			}
			@include tabletMaxi {
				width: 420px;
				height: 420px;
				margin-top: 100px;
				right: -223px;
			}
			@include mobile {
				width: 496px;
				height: 496px;
				right: -257px;
				top: 100%;
				margin-top: 280px;
			}

			&:after {
				width: 40px;
				height: 40px;
				top: -20px;
				margin-left: -20px;
				@include tabletMaxi {
					width: 32px;
					height: 32px;
					top: -16px;
					margin-left: -16px;
				}
			}
		}

		&_two {
			color: #9186FF;
			width: 296px;
			height: 296px;
			right: -235px;
			margin-top: 14px;
			animation: {
				name: circleCap2;
				duration: 40s;
				timing-function: linear;
				iteration-count: infinite;
			}
			@include tabletMaxi {
				width: 246px;
				height: 246px;
				margin-top: 100px;
				right: -192px;
			}
			@include mobile {
				width: 296px;
				height: 296px;
				top: 100%;
				margin-top: 280px;
				right: -216px;
			}
			&:after {
				width: 18px;
				height: 18px;
				top: -9px;
				margin-left: -9px;
				@include tabletMaxi {
					width: 14px;
					height: 14px;
					top: -7px;
					margin-left: -7px;
				}
			}
		}
	}
}

@keyframes circleCap1 {
	from {
		transform: translateY(-50%) rotate(180deg);
	}
	to {
		transform: translateY(-50%) rotate(-180deg);
	}
}

@keyframes circleCap2 {
	from {
		transform: translateY(-50%) rotate(300deg);
	}
	to {
		transform: translateY(-50%) rotate(-60deg);
	}
}


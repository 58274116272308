.contacts-box {
	margin-top: 220px;
	margin-bottom: 200px;
	@include tabletMaxi {
		margin-bottom: 180px;
	}
	@include mobile {
		margin-top: 250px;
		margin-bottom: 212px;
	}
}

.contact-bl {
	&__inner {
		max-width: 775px;
		margin-bottom: 190px;
		@include tabletMaxi {
			max-width: none;
		}
		@include mobile {
			margin-bottom: 160px;
		}
	}
	&__tt {
		font-weight: 600;
		font-size: 36px;
		line-height: 120%;
		letter-spacing: 0.04em;
		text-transform: uppercase;
		color: #523AE2;
		margin-bottom: 55px;
		@include mobile {
			margin-bottom: 41px;
			font-size: 24px;
		}
	}
	&__wrapp-right {
		margin-left: 33.3%;
		padding-left: 10px;
		@include mobile {
			padding-left: 0;
		}
		@include tabletMaxi {
			margin-left: 0;
			padding-left: 0;
		}
	}
	&__txt {
		font-size: 18px;
		line-height: 120%;
		letter-spacing: 0.03em;
		color: #000000;
		max-width: 675px;
		margin-bottom: 30px;
		@include mobile {
			margin-bottom: 34px;
		}
	}
	&__link {
		font-size: 28px;
		line-height: 100%;
		letter-spacing: 0.03em;
		text-decoration-line: underline;
	}
}

a.contact-bl__link {
	&:hover {
		text-decoration: none;
	}
}

.form-group {
	margin-bottom: 10px;
	@include mobile {
		input.form-group__field {
			height: 48px;
			padding-left: 23px;
			padding-right: 23px;
			font-size: 16px;
		}
	}
	&__field {
		width: 100%;
	}
	textarea {
		padding-top: 78px;
		@include mobile {
			height: 120px;
			padding-top: 77px;
			padding-left: 23px;
			padding-right: 23px;
		}
	}
	&-grid {
		display: grid;
		grid-template-columns: 1fr 200px;
		grid-gap: 50px;
		margin-top: 50px;
		@include mobile {
			grid-template-columns: 1fr;
			grid-gap: 21px;
		}
	}
	&_desc {
		font-size: 14px;
		line-height: 120%;
		letter-spacing: 0.04em;
		max-width: 380px;
		@include mobile {
			margin-bottom: 0;
		}
		a {
			text-decoration: underline;
			&:hover {
				text-decoration: none;
			}
		}
	}
}

.img-md {
	height: 600px;
	position: relative;
	overflow: hidden;
	img {
		width: 100%;
		height: 100%;
		display: block;
		object-fit: cover;
	}
}




.hero {
	padding-bottom: 460px;
	position: relative;
	z-index: 1;
	@include tabletMaxi {
		padding-bottom: 238px;
	}
	@include mobile {
		padding-bottom: 0;
	}
	&__bg {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
		background-color: #523AE2;
		img, video {
			display: block;
			object-fit: cover;
			width: 100%;
			height: 100%;
		}
	}
	&__inner {
		min-height: 100vh;
		padding: 120px 0 35px;
		color: #fff;
		display: flex;
		justify-content: center;
		align-items: flex-start;
		flex-direction: column;
		@include tabletMaxi {
			padding-bottom: 38px;
		}
		.h1 {
			margin-top: auto;
			margin-bottom: 50px;
			span {
				color: #FFA729;
				display: block;
				@include tabletMaxi {
					display: inline;
				}
			}
			.db {
				white-space: pre-wrap;
				@media (max-width: 370px) {
					.word {
						.char {
							display: inline !important;
						}
					}
				}
			}
		}
	}
	&__scroll {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: auto;
		width: 100%;
		@include mobile {
			justify-content: center;
		}
		&-txt {
			font-weight: 500;
			font-size: 14px;
			line-height: 100%;
			letter-spacing: 0.1em;
			@include mobile {
				display: none;
			}
		}
	}
}


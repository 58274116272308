.preview-txt {
	margin: 180px 0 167px;
	+ .cards-section {
		margin-top: 0;
	}
	@include tabletMaxi {
		margin-top: 200px;
		margin-bottom: 100px;
	}
	@include mobile {
		margin-top: 170px;
		margin-bottom: 170px;
	}
	&__inner {
		margin-left: 198px;
		@include tabletMaxi {
			margin-left: 0;
		}
	}
	.h3 {
		font-weight: 600;
		font-size: 36px;
		line-height: 120%;
		letter-spacing: 0.04em;
		text-transform: uppercase;
		color: #523AE2;
		margin-bottom: 36px;
		@include tabletMaxi {
			margin-bottom: 31px;
		}
		@include mobile {
			margin-bottom: 42px;
		}
	}
	p {
		max-width: 675px;
	}
}

.history-box {
	margin-bottom: 200px;
	@include tabletMaxi {
		margin-bottom: 211px;
	}
	@include mobile {
		margin-bottom: 170px;
	}
	.img-big {
		margin-bottom: 104px;
		@include tabletMaxi {
			margin-bottom: 100px;
			height: 328px;
		}
		@include mobile {
			margin-bottom: 114px;
			height: 438px;
			margin-left: 0;
			margin-right: 0;
		}
	}
}

.history-info {
	.subtitle {
		color: #523AE2;
		margin-bottom: 25px;
	}
	.txt-md {
		margin-bottom: 50px;
		font-weight: 500;
		font-size: 24px;
		line-height: 120%;
		letter-spacing: 0.03em;
		max-width: 975px;
		@include tabletMaxi {
			margin-bottom: 65px;
		}
		@include mobile {
			font-weight: 500;
			font-size: 18px;
			line-height: 120%;
			margin-bottom: 52px;
		}
		p {
			margin-bottom: 15px;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	.link-st {
		color: #523AE2;
		font-weight: 500;
		font-size: 14px;
		line-height: 100%;
		letter-spacing: 0.1em;
	}
}



.partners-section {
	margin-bottom: 200px;
	@include mobile {
		margin-bottom: 170px;
	}
}

.partners-cap {
	display: flex;
	margin-bottom: 75px;
	@include tabletMaxi {
		display: block;
		margin-bottom: 87px;
	}
	@include mobile {
		margin-bottom: 37px;
	}
	.subtitle {
		color: #523AE2;
		margin-bottom: 25px;
	}
	&__left {
		margin-right: 90px;
		@include tabletMaxi {
			margin-right: 0;
			margin-bottom: 61px;
		}
	}
	&__right {
		flex-shrink: 0;
		padding-top: 42px;
		@include tabletMaxi {
			padding-top: 0;
		}
	}
}

.partners-cards {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 20px;
	@include tabletMaxi {
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 40px;
	}
	@include mobile {
		grid-template-columns: 1fr;
		grid-gap: 30px;
	}
}

a.partner-card {
	&:hover {
		opacity: 0.8;
	}
}
.partner-card {
	display: block;
	height: 240px;
	overflow: hidden;
	@include tabletMaxi {
		height: 191px;
	}
	@include mobile {
		height: 204px;
	}
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		display: block;
	}
}

.section-st {
	margin-bottom: 200px;
	@include mobile {
		margin-bottom: 170px;
	}
}



.hero-pillar {
	color: #fff;
	.hero-inner {
		padding-bottom: 140px;
		@include tabletMaxi {
			justify-content: flex-start;
			flex-grow: 1;
			min-height: 100vh;
			padding-bottom: 170px;
			padding-top: 160px;
		}
		@include mobile {
			padding-bottom: 50px;
			padding-top: 70px;
		}
		&__grid {
			display: flex;
			justify-content: space-between;
			width: 100%;
			@include tabletMaxi {
				flex-direction: column;
				flex-grow: 1;
				justify-content: center;
			}
		}
		&__value {
			font-weight: 300;
			font-size: 16px;
			line-height: 120%;
			letter-spacing: 0.155em;
			color: #9186FF;
			width: 198px;
			flex-shrink: 0;
			margin-bottom: 39px;
			span {
				font-weight: 700;
				font-size: 18px;
				position: relative;
				bottom: -1px;
			}
			@include tabletMaxi {
				margin-top: auto;
			}
			@include mobile {
				margin-bottom: 30px;
				margin-top: auto;
			}
		}
		&__info {
			margin-top: -8px;
			flex-grow: 1;
			@include tabletMaxi {
				flex-grow: 0;
			}
			.h2 {
				@include mobile {
					margin-bottom: 27px;
				}
			}
		}
		&__link {
			width: 198px;
			flex-shrink: 0;
			align-self: flex-start;
			text-align: right;
			position: relative;
			top: -5px;
			@include tabletMaxi {
				margin-top: auto;
				margin-left: auto;
				width: auto;

			}
			@include mobile {
				padding-right: 20px;
			}
			.link-arrow-big {
				@include tabletMaxi {
					margin-right: 0;
				}
			}
		}
	}
}

.hero-circle1 {
	width: 284px;
	height: 284px;
	border-radius: 50%;
	border: 1px solid #fff;
	position: absolute;
	bottom: 57px;
	right: -102px;
	z-index: -1;
	@include tabletMaxi {
		bottom: 82px;
    right: -180px;
	}
	@include mobile {
		display: none;
	}
}

.hero-circle2 {
	width: 437px;
	height: 437px;
	border-radius: 50%;
	border: 1px solid #9186FF;
	position: absolute;
	bottom: 32px;
	right: -236px;
	z-index: -1;
	@include tabletMaxi {
		bottom: 32px;
    right: -326px;
	}
	@include mobile {
		display: none;
	}
}

.link-arrow-big {
	font-weight: 500;
	font-size: 14px;
	line-height: 120%;
	letter-spacing: 0.1em;
	text-transform: uppercase;
	color: #9186FF;
	padding-right: 92px;
	position: relative;
	&:hover {
		opacity: 0.8;
	}
	&:after {
		content: "";
		position: absolute;
		width: 70px;
		height: 70px;
		border-radius: 50%;
		top: 50%;
		margin-top: -35px;
		right: 0;
		background: #9186FF url(../img/arrow-right-slide.svg) center center no-repeat;

	}

}

.mob-dots {
	display: none;
	@include mobile {
		display: block;
		display: flex;
		align-items: center;
		margin-top: 30px;

		span, a {
			display: block;
			width: 8px;
			height: 8px;
			border-radius: 50%;
			background-color: #523AE2;
			margin-right: 26px;
			cursor: pointer;
			&.active {
				background-color: #FFA729;
				cursor: default;
			}
		}
		a {
			position: relative;
			&:after {
				content: "";
				position: absolute;
				top: -10px;
				left: -10px;
				right: -10px;
				bottom: -10px;
			}
		}
		* {
			&:last-child {
				margin-right: 0;
			}
		}
	}
}

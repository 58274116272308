.split-parent {
	overflow: hidden;
}

// [data-anim-fader], [data-anim-fadey], [data-anim-fadel] {
// 	opacity: 0;
// }

.und {
	position: relative;
	line-height: 1.2 !important;
	text-decoration: none !important;
	background-image: linear-gradient(
		transparent 0%,
		transparent 85%,
		currentColor 85%,
		currentColor 91%,
		transparent 91%,
		transparent 100%
	);
	background-repeat: no-repeat;
	background-size: 0% 100%;
	background-position-x: left;

	transition: background-size 0.25s ease;

	&:hover,
	&:active {
		background-size: 100% 100%;
		background-position-x: left;
		text-decoration: none !important;
	}
}

.und2 {
	position: relative;
	line-height: 1.2 !important;
	text-decoration: none !important;
	background-image: linear-gradient(
		transparent 0%,
		transparent 85%,
		currentColor 85%,
		currentColor 90%,
		transparent 90%,
		transparent 100%
	);
	background-repeat: no-repeat;
	background-size: 100% 100%;
	background-position-x: left;

	transition: background-size 0.25s ease;

	&:hover,
	&:active {
		background-size: 0% 100%;
		background-position-x: left;
		text-decoration: none !important;
	}
}

.und2.slider-circle__link {
	position: relative;
	display: inline-block;
	background-size: 0;
	&:after {
		content: "";
		position: absolute;
		left: 0;
		right: 0;
		height: 1px;
		bottom: 0;
		background-color: currentColor;
		transition: all 0.2s ease-in-out;
	}
	&:hover {
		&:after {
			right: 100%;
		}
	}
}

.open-txt {
	&:not(.open-txt_open) {
		.open-txt__open {
			margin: 0 !important;
			&:nth-child(3) {
				margin-top: -15px !important;
			}
		}
	}
	&_open {

		[data-open-txt] {
			span {
				&:first-child {
					display: none;
				}
				&:last-child {
					display: block;
				}
			}
		}
		.open-txt__open {
			max-height: 999px;
			opacity: 1;
		}
	}
}
.open-txt__open {
	max-height: 0;
	opacity: 0;
	transition: max-height 0.5s ease-in-out, all 0.3s ease;
	margin: 0;
}

[data-open-txt] {
	span {
		&:first-child {
			display: block;
		}
		&:last-child {
			display: none;
		}
	}
}

.db {
	display: block !important;
}

.split,
.split-hero {
	opacity: 0;
	&.splitting {
		opacity: 1;
	}
	.line {
		overflow: hidden;
	}
	.char {
		transform: translateY(0%);
	}
}

.loader-wrapper {
  --line-width: 5px;
  --curtain-color: #9186ff;
  --outer-line-color: #ffa729;
  --middle-line-color: #523ae2;
  --inner-line-color: #1d3557;
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height:100%;
  z-index:2000;
}

.loader {
  display:block;
  position: relative;
  top:50%;
  left:50%;
/*   transform: translate(-50%, -50%); */
  width:150px;
  height:150px;
  margin:-75px 0 0 -75px;
  border:var(--line-width) solid transparent;
  border-top-color: var(--outer-line-color);
  border-radius:100%;
  -webkit-animation: spin 2s linear infinite;
          animation: spin 2s linear infinite;
  z-index:1001;
}


.loader:after {
  content:"";
  position: absolute;
  top:14px;
  left:14px;
  right:14px;
  bottom:14px;
  border:var(--line-width) solid transparent;
  border-top-color: var(--middle-line-color);
  border-radius:100%;
  -webkit-animation: spin 1.5s linear infinite;
          animation: spin 1.5s linear infinite;
}

@-webkit-keyframes spin {
  0%   {
    -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
          transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
          transform: rotate(360deg);
  }
}
@keyframes spin {
  0%   {
    -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
          transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
          transform: rotate(360deg);
  }
}

.loader-wrapper .loader-section {
  position:fixed;
  top:0;
  background:var(--curtain-color);
  width:51%;
  height:100%;
  z-index:1000;
}

.loader-wrapper .loader-section.section-left {
  left:0
}
.loader-wrapper .loader-section.section-right {
  right:0;
}

/* Loaded Styles */
.loaded .loader-wrapper .loader-section.section-left {
  transform: translateX(-100%);
  transition: all 0.7s 0.3s cubic-bezier(0.645,0.045,0.355,1.000);
}
.loaded .loader-wrapper .loader-section.section-right {
  transform: translateX(100%);
  transition: all 0.7s 0.3s cubic-bezier(0.645,0.045,0.355,1.000);
}
.loaded .loader {
  opacity: 0;
  transition: all 0.3s ease-out;
}
.loaded .loader-wrapper {
  visibility: hidden;
  transform:translateY(-100%);
  transition: all .3s 1s ease-out;
}



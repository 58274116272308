.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
	font-weight: 500;
	margin-bottom: 20px;
	letter-spacing: 0.03em;
}


.h1, h1 {
	font-weight: 500;
	font-size: 64px;
	line-height: 120%;
	@include tabletMaxi {
		font-size: 54px;
	}
	@include mobile {
		font-size: 42px;
	}
}

.h2, h2 {
	font-weight: 500;
	font-size: 48px;
	line-height: 120%;
	letter-spacing: 0.03em;
	margin-bottom: 35px;
	@include mobile {
		font-size: 36px;
	}
}

.h3, h3 {
	font-weight: 500;
	font-size: 36px;
	line-height: 120%;
	letter-spacing: 0.03em;
	@include mobile {
		font-size: 24px;
	}
	&.tt-big-mob {
		font-size: 36px;
	}
}

.tt-up {
	text-transform: uppercase;
}

.tt-strong {
	font-weight: 600;
}

.txt-md {
	font-size: 24px;
	line-height: 1.2;
}

.color-second {
	color: #FFA729;
}

.color-three {
	color: #523AE2;
}

.color-four {
	color: #9186FF;
}

.subtitle {
	padding-left: 25px;
	position: relative;
	font-weight: 500;
	font-size: 14px;
	text-transform: uppercase;
	letter-spacing: 0.1em;
	color: #FFA729;
	margin-bottom: 11px;
	&:before {
		content: "";
		position: absolute;
		top: 50%;
		left: 0;
		margin-top: -7px;
		width: 14px;
		height: 14px;
		border-radius: 50%;
		background: currentColor;
	}
}

.txt-bl {
	p {
		margin-bottom: 20px;
		@include mobile {
			font-size: 16px;
			line-height: 1.2;
		}
	}
	ul {
		margin-top: 100px;
		padding-top: 50px;
		margin-bottom: 113px;
		display: block;
		border-top: 1px solid rgba(#000, 0.3);
		li {
			line-height: 120%;
			margin-bottom: 20px;
			list-style-type: disc;
			list-style-position: inside;
			&:last-child {
				margin-bottom: 0;
			}
		}
		@include mobile {
			margin-top: 70px;
		}
	}
	.txt-md {
		@include mobile {
			font-size: 18px;
			line-height: 120%;
		}
	}
	.left-wrapp {
		max-width: calc(100% - 198px);
		margin-left: auto;
		margin-right: auto;
		padding-left: 98px;
		margin-bottom: 100px;
		@include tabletMaxi {
			max-width: none;
			padding-left: 0;
		}
		&:nth-child(3) {
			@include tabletMaxi {
				margin-bottom: 54px;
			}
		}
	}

	> .center-wtapp {
		max-width: calc(100% - 198px);
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 105px;
		@include tabletMaxi {
			max-width: none;
			margin-bottom: 120px;
		}
		@include mobile {
			margin-bottom: 70px;
		}
	}

	h3 {
		margin-bottom: 45px;
		@include tabletMaxi {
			margin-bottom: 42px;
		}
	}

	img, .img-md {
		margin-bottom: 118px;
		@include tabletMaxi {
			margin-bottom: 73px;
		}
		@include mobile {
			margin-bottom: 70px;
		}
	}

  b, strong {
    font-weight: 700;
  }

  i, em {
    font-style: italic;
  }

  a {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

	blockquote {
		p {
			font-size: 24px;
			line-height: 120%;
			letter-spacing: 0.03em;
			color: #523AE2;
			margin-bottom: 36px;
		}
		span {
			display: block;
			font-weight: 600;
			font-size: 16px;
			line-height: 120%;
			letter-spacing: 0.03em;
			color: #523AE2;
		}
	}
}

.plyr__video-embed {
	iframe {
		width: 100%;
		height: 547px;
	}
}

.pages-list-nav {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	min-height: 100vh;
	line-height: 40px;
	text-transform: uppercase;
	font-weight: 500;
	a {
		&:hover {
			text-decoration: underline;
		}
	}
}


.hero-news {
	margin-top: 220px;
	margin-bottom: 70px;
	@include tabletMaxi {
		margin-bottom: 73px;
	}
	@include mobile {
		margin-top: 250px;
		margin-bottom: 47px;
	}
	.h3 {
		margin-bottom: 30px;
		font-weight: 600;
		font-size: 36px;
		line-height: 120%;
		letter-spacing: 0.04em;
		text-transform: uppercase;
		color: #523AE2;
		max-width: 975px;
		@include mobile {
			font-size: 24px;
		}
	}
}

.markers {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	margin-bottom: 24px;
	&_last {
		margin-bottom: 150px;
		padding-top: 24px;
		border-top: 1px solid rgba(#000, 0.6);
	}
	&__item {
		margin-right: 26px;
		font-weight: 400;
		font-size: 16px;
		line-height: 120%;
		letter-spacing: 0.03em;
		color: #000000;
		opacity: 0.6;
		@include mobile {
			&:first-child {
				width: 100%;
				margin-bottom: 25px;
			}
		}
		&:last-child {
			margin-right: 0;
		}
		a {
			text-decoration: underline;
			&:hover {
				text-decoration: none;
			}
		}
	}
}

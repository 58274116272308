.hero-inner {
	&_light {
		color: #fff;
	}
	&-wrapper {
		overflow: hidden;
		position: relative;
	}
	&-bg {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		z-index: -1;
		img, video, iframe {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	min-height: 620px;
	height: 90vh;
	padding: 100px 0;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: flex-start;
	@include tabletMaxi {
		min-height: 933px;
	}
	@include mobile {
		min-height: 100vh;
		.h2 {
			margin-bottom: 24px;
		}
	}
	p {
		max-width: 775px;
	}
}



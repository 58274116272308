.tabs-section {
	margin-bottom: 185px;
	@include tabletMaxi {
		margin-bottom: 197px;
	}
	@include mobile {
		margin-bottom: 165px;
	}
	.article-card {
		background: #F0F0F0;
		&__footer {
			border-color: #fff;
		}
	}
	.article-cards {
		margin-bottom: 85px;
		@include tabletMaxi {
			margin-bottom: 70px;
		}
		@include mobile {
			margin-bottom: 90px;
		}
	}
	.caption {
		margin-bottom: 80px;
		@include tabletMaxi {
			margin-bottom: 120px;
		}
		@include mobile {
			margin-bottom: 50px;
		}
		.h2 {
			font-weight: 600;
			line-height: 110%;
			letter-spacing: 0.04em;
			text-transform: uppercase;
			margin-bottom: 45px;
			@include tabletMaxi {
				font-size: 38px;
			}
			@include mobile {
				font-size: 24px;
			}
			span {
				color: #523AE2;
			}
		}
	}
}



.tabs__list {
	margin-bottom: 27px;
}

.tab-list {
	display: flex;
	flex-wrap: wrap;

	&__item {
		margin-right: 35px;
		@include mobile {
			margin-bottom: 27px;
		}
		&:last-child {
			margin-right: 0;
		}
	}
}

.tab-box {
	display: none;
	&.active {
		display: block;
	}
	.doc-box {
		margin-bottom: 15px;
		@include mobile {
			margin-bottom: 30px;
		}
	}
}

.tabs__boxes {
	&.active {
		.tab-box {
			display: block;
		}
	}
}

.doc-box {
	position: relative;
	display: block;
	padding: 34px 80px 34px 39px;
	background: #F0F0F0;
	@include mobile {
		padding: 34px 80px 40px 32px;
	}
	&:hover {
		background: #9186FF;
	}
	&__tt {
		margin-bottom: 12px;

		font-weight: 500;
		font-size: 20px;
		line-height: 120%;
		letter-spacing: 0.03em;
		text-transform: uppercase;
		@include mobile {
			margin-bottom: 20px;
		}
	}
	&__desc {
		display: flex;
		@include mobile {
			display: block;
		}
		* {
			margin-right: 35px;
			&:last-child {
				margin-right: 0;
			}
			@include mobile {
				margin-bottom: 13px;
				&:last-child {
					margin-bottom: 0;
				}
			}

		}
	}
	&__data,
	&__txt {
		font-weight: 400;
		font-size: 14px;
		line-height: 100%;
		letter-spacing: 0.04em;
		text-transform: uppercase;
		color: #000000;
		opacity: 0.6;
	}
	&__txt {
		text-transform: none;
	}
	&:after {
		background: url(../img/arrow-doc.svg) center center no-repeat;
		width: 22px;
		height: 23px;
		position: absolute;
		top: 36px;
		right: 47px;
		content: "";
		@include mobile {
			top: auto;
			bottom: 32px;
			right: 43px;
		}
	}
}

.news-card {
	background: #F0F0F0;
	display: block;
	&.news-card_tabl-hidden {
		@include tabletMaxi {
			display: none;
		}
		@include mobile {
			display: block;
		}
	}
	&__img {
		background: #E7ECF3;
		height: 200px;
		overflow: hidden;
		display: block;
		&:hover {
			img {
				transform: scale(1.1);
			}
		}
		img {
			width: 100%;
			height: 100%;
			display: block;
			object-fit: cover;
			transform: scale(1);
			transition: all 0.2s ease-in-out;
		}
	}
	&__info {
		padding: 50px 41px 58px;
		@include tabletMaxi {
			padding: 47px 31px 53px;
		}
	}
	&__tt {
		font-weight: 600;
		font-size: 24px;
		line-height: 120%;
		letter-spacing: 0.03em;
		color: #000000;
		display: -webkit-box;
		-webkit-line-clamp: 4;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
		margin-bottom: 29px;

		@include tabletMaxi {
			font-size: 20px;
		}
	}
	&__link {
		font-weight: 500;
		font-size: 14px;
		line-height: 120%;
		letter-spacing: 0.1em;
		color: #523AE2;
	}
}

.news-cards {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 20px;
	@include tabletMaxi {
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 40px;
	}
	@include mobile {
		grid-template-columns: 1fr;
		grid-gap: 30px;
	}
}

.article-section {
	.article-box__cap {
		margin-bottom: 95px;
		@include tabletMaxi {
			margin-bottom: 60px;
		}
		@include mobile {
			margin-bottom: 80px;
		}
		.subtitle {
			margin-bottom: 25px;
			@include tabletMaxi {
				margin-bottom: 11px;
			}
			@include mobile {
				margin-bottom: 25px;
			}
		}
	}
}


.footer {
	&__inner {
		border-top: 2px solid rgba(#000, 0.3);
		padding-top: 65px;
		padding-bottom: 161px;
		display: flex;
		flex-wrap: wrap;
		@include tabletMaxi {
			justify-content: space-between;
			padding-top: 50px;
			padding-bottom: 155px;
		}
		@include mobile {
			padding-top: 70px;
			padding-bottom: 100px;
		}
	}
	&__logo {
		width: 33.3%;
		padding-right: 10px;
		@include tabletMaxi {
			width: 100%;
			margin-bottom: 127px;
		}
		@include mobile {
			margin-bottom: 85px;
		}
	}
	&__nav-big {
		width: 33.3%;
		font-size: 28px;
		line-height: 100%;
		padding-right: 10px;
		padding-left: 8px;
    padding-top: 12px;
		@include tabletMaxi {
			width: 350px;
			padding-left: 0;
		}
		@include mobile {
			font-size: 24px;
			padding-right: 0;
			width: 100%;
			margin-bottom: 70px;
		}
		li {
			margin-bottom: 48px;
			&:last-child {
				margin-bottom: 0;
			}
			@include tabletMaxi {
				margin-bottom: 44px;
			}
		}
		a {
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
		}
	}
	&__nav-sm {
		width: 33.3%;
		font-size: 16px;
		padding-right: 10px;
		line-height: 18px;
		padding-left: 12px;
    padding-top: 12px;
		.open-tabl {
			display: none;
		}
		@include tabletMaxi {
			width: 233px;
			padding-left: 0;
			.open-tabl {
				display: block;
			}
		}
		li {
			margin-bottom: 30px;
			&:last-child {
				margin-bottom: 0;
			}
		}
		a {
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
		}
	}

	&__desc {
		display: flex;
		padding-bottom: 44px;
		@include mobile {
			padding-bottom: 40px;
		}
	}
	&__copy {
		width: 33.3%;
		padding-right: 10px;
		font-size: 16px;
		line-height: 18px;
		@include tabletMaxi {
			width: auto;
		}
	}
	&__nav-privat {
		display: flex;
		font-size: 16px;
		line-height: 18px;
		padding-left: 12px;
		@include tabletMaxi {
			display: none;
		}
		li {
			margin-right: 84px;
			position: relative;
			&:after {
				content: "";
				position: absolute;
				right: -42px;
				width: 1px;
				height: 32px;
				top: 50%;
				margin-top: -16px;
				background-color: rgba(#000, 0.3);
			}
			&:last-child {
				margin-right: 0;
				&:after {
					display: none;
				}
			}
		}
		a {
			text-decoration: underline;
			&:hover {
				text-decoration: none;
			}
		}
	}
}



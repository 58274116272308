.caption {
	.subtitle {
		margin-bottom: 25px;
	}
	.h3 {
		margin-bottom: 57px;
		@include tabletMaxi {
			margin-bottom: 67px;
		}
	}
	&_push-lg {
		margin-bottom: 210px;
		@include tabletMaxi {
			margin-bottom: 200px;
		}
		@include mobile {
			margin-bottom: 170px;
		}
	}
	&_max-width {
		.h3 {
			max-width: 860px;
		}
	}
}

.subtitle_dark {
	color: #523AE2;
}


// breakpoints (desktop first)
$b-mobile-mini: 479px;
$b-mobile-midi: 639px;
$b-mobile: 767px;
$b-tablet: 894px;
$b-tablet-maxi: 1054px;
$b-labtop-mini: 1299px;
$b-laptop: 1439px;
$b-desktop: 1899px;
$b-desktop-maxi: 2239px;

//fonts
$font-default: 'Poppins', helvetica, tahoma, arial, sans-serif;
$font-accent: 'Poppins', helvetica, tahoma, arial, sans-serif;
$font-1: 'Poppins', helvetica, tahoma, arial, sans-serif;

//colors
$c-black: #000;
$c-white: #fff;
$c-accent: #fff;

// timing
$time-sm: 300ms;
$time-md: 500ms;
$time-lg: 800ms;
$time-xl: 1100ms;

// layout
$container: 1180px;
$wrap-lg: 60px;
$wrap-md: 45px;
$wrap-sm: 30px;

// tags
$h: "h1, h2, h3, h4, h5, h6";
$input-txt: 'input[type="email"], input[type="number"], input[type="password"], input[type="search"], input[type="tel"], input[type="text"], input[type="url"], input[type="color"], input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="month"], input[type="time"], input[type="week"], textarea';

// z-index
$z-index: (
	cursor    : 100000000,
	modal     : 1200,
	overlay   : 1100,
	header    : 1000,
	footer    : 900,
	dropdown  : 800
);
